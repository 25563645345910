import enLocale from 'date-fns/locale/en-US'
import ruLocale from 'date-fns/locale/ru'
import uzLocale from 'date-fns/locale/uz'

export default {
  computed: {
    dateFnsLocale() {
      switch (this.$i18n.locale) {
        case 'ru':
          return ruLocale
        case 'uz':
          return uzLocale
        default:
          return enLocale
      }
    },
  }
}
